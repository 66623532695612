@use "sass:map";
@use '../../../sass/base/index.scss';
@use "../../../sass/base/mixins";
@use "../../../sass/base/variables";

.study-filter {
  &__active {
    @extend .mt-lg;
  }

  >.expandable-field.is-open>.expandable-field__content {
    @extend .mt-xl;
  }

  .grid-split {
    grid-template-rows: max-content;

    @include mixins.breakpoint(sm) {
      > :last-child {
        grid-column: 2;
        grid-row: 1/3;
      }
    }
  }

  .filter-button {
    @extend .bg-dark-gray-1;
    @extend .text-light-white;
    border: none;
    padding: 8px 12px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;

    &:hover {
      .icon {
        color: variables.$color-error;
      }
    }

    .icon {
      margin-left: 8px;
    }

    &--reset,
    &--link {
      text-decoration: underline;
      background: none;
      padding: 0;
      color: inherit;

      .icon {
        color: variables.$color-primary;
      }
    }
  }

  .filter-button-wrapper {
    margin-top: -(map.get(variables.$spacing, xs));

    .icon {
      path {
        fill: #fff;
      }
    }
  }
}

.expandable-field__content {
  display: flex;
  flex-direction: column;
}
