.edit-additional-points {
  &__yob {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__points {
    align-self: flex-end !important;
    margin-top: 10px;
  }

  &__checklist {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 12 px;
  }

  .expandable-content-container {
    > :last-child {
      margin-bottom: 0;
    }
  }
}