@font-face {
  font-family: "Roboto";
  src: url(../../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(../../fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(../../fonts/Roboto-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Slab";
  src: url(../../fonts/RobotoSlab-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab";
  src: url(../../fonts/RobotoSlab-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}