@use '../../../../sass/base/index.scss';
@use "../../../../sass/base/variables";

.requirement {
  &__label {
    @extend .font-bold;
    margin-bottom: 5px;
  }

  &__icon {
    margin-right: 7px;
    position: relative;
    top: -1px;
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &--pass {
    .requirement__icon {
      color: variables.$color-green;
    }
  }

  &--fail {
    .requirement__icon {
      color: variables.$color-red;
    }
  }

  &--unknown {
    .requirement__icon {
      color: variables.$color-gray;
    }
  }
}
