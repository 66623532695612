@use "../base/variables.scss" as var;

// Margins
// Example classes:
//   .mt-xs = margin-top: 10px (extra small)
//   .mb-md = marbin-bottom: 20px (medium)
@each $letter,
$direction in (t: top, b: bottom) {

  @each $size,
  $value in var.$spacing {
    .m#{$letter}-#{$size} {
      margin-#{$direction}: $value;
    }
  }

  ;
}

;
