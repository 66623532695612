@use "../../sass/base/index.scss";
@use "../../sass/base/variables";

.edit-points {
  @extend .text-sm;

  > :last-child {
    margin-bottom: 0;
  }

  .page-layout__aside & {
    @extend .bg-light-white;
    padding: 25px 15px;
  }

  p {
    margin-bottom: 0;
  }

  label {
    .page-layout__aside & {
      @extend .font-bold;
    }
  }

  button:not(.expandable-field__toggle) {

    &:hover {
      .icon {
        color: variables.$color-primary;
      }
    }

    .icon {
      margin-left: 5px;
    }
  }
}

.expandable-field__content button,
.edit-points__reset {
  text-align: start;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  padding: 0;
  text-decoration: underline;
  font-weight: 600;
}
