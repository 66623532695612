@use "../../../sass/base/index.scss";
@use "../../../sass/base/variables";

.add-subject-search-result {
  border: none;
  background-color: variables.$color-white;
  padding: 15px;
  margin-top: 15px;
  width: 100%;
  text-align: left;

  &:hover {
    .fake-button svg {
      background-color: variables.$color-primary;
      @extend .shadow-2;
    }
  }

  &__name {
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .fake-button {
    @extend .font-bold;
    text-decoration: underline;
  }

  svg {
    margin-left: 5px;

    border: 2px solid variables.$color-primary;
    border-radius: 20px;
    padding: 2px;
    box-sizing: content-box;
  }

  +.subject-search-result {
    margin-top: 10px;
  }
}
