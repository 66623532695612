@use '../../sass/base/index.scss';
@use "../../sass/base/variables";

.infobox {
  @extend .bg-light-white;
  padding: 30px variables.$layout-margin;
  position: relative;
  margin-top: 11px + 10;

  &__header {
    @extend .font-bold;
    display: flex;
    margin-bottom: 10px;
  }

  &__icon {
    color: variables.$color-primary;
    margin-right: 10px;
  }

  &__close {
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    margin-left: auto;
  }

  ul:not(.list-reset) {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 24px;
  }

  li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
