@use "sass:map";
@use '../../../sass/base/index.scss';
@use "../../../sass/base/variables";

.study {
  @extend .text-sm;
  @extend .bg-light-white;
  border: 2px solid #dbdbdb;
  border-top: 2px solid variables.$color-primary;

  // Element styles
  // Could be moved to global styles
  hr {
    border: none;
    height: 1px;
    margin: 24px 0;
    background: linear-gradient(to right, variables.$color-gray 2px, transparent 2px);
    background-size: 6px;
  }

  a {
    color: variables.$color-link;
  }

  table {
    width: 100%;
  }

  thead {
    border-bottom: 1px solid variables.$color-text;
    text-align: left;
  }

  th {
    white-space: nowrap;
    padding: 4px 12px 4px 0;
  }

  td {
    padding: 12px 12px 12px 0;
  }

  // End Element styles

  &__header {
    padding: 16px 16px 20px;
  }

  &__toggle-open {
    @extend .mb-xs;
    border: none;
    background: none;
    padding: 0;
    text-align: inherit;
    font-size: inherit;
    width: 100%;

    &:hover,
    &:focus {
      .icon {
        color: variables.$color-primary-darken;
      }
    }
  }

  &__title {
    @extend .text-md;
    @extend .font-bold;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .icon {
      color: variables.$color-primary;
      width: 16.36px;
      height: 11.45px;
      margin-left: 10px;
      flex-shrink: 0;
      transition: transform 0.2s ease-in-out;

      .is-open.study & {
        transform: scaleY(-1);
      }
    }
  }

  &__title-2 {
    @extend .text-md;
    margin-bottom: 5px;
  }

  &__university {
    margin-bottom: 16px;
  }

  // &__up,
  // &__down {
  //   display: flex;
  //   align-items: center;

  //   &:after {
  //     content: '';
  //     margin-left: 8px;
  //     height: 0;
  //     width: 0;
  //     border-left: 4px solid transparent;
  //     border-right: 4px solid transparent;
  //   }
  // }

  // &__up:after {
  //   border-bottom: 7px solid $color-green;
  // }

  // &__down:after {
  //   border-top: 7px solid $color-red;
  // }

  &__requirements {
    margin-top: -(map.get(variables.$spacing, xs));
    display: flex;
    align-items: flex-end;
    //flex-wrap: wrap;

    >* {
      @extend .mt-xs;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    visibility: hidden;
    max-height: 0;
    opacity: 0;

    .study.is-open & {
      visibility: visible;
      max-height: none;
      opacity: 1;
      transition: opacity 0.2s ease-out;
    }

    li+li {
      margin-top: 16px;
    }
  }

  &__favorites {
    border: none;
    padding: 0;
    background: none;
    text-decoration: underline;
    display: flex;
    align-items: center;
    font-size: inherit;

    &.is-favorite {
      .icon {
        color: variables.$color-primary;
      }
    }

    .icon {
      margin-right: 5px;
      width: 20px;
    }
  }

  &__body {
    padding: 0 16px 24px;

    >hr:first-child {
      margin-top: 0;
    }

    .requirement--label+.requirement--label {
      @extend .mt-sm;
    }
  }
}

.requirement--accepted {
  .study.is-open & {
    @extend .mt-md;
  }
}
