@use '../../sass/base/index.scss';
@use "../../sass/base/variables";

.link-panel {
  @extend .bg-light-white;
  @extend .text-md;
  width: 100%;
  text-decoration: none;
  padding: 16px 20px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;

  &:hover {
    text-decoration: underline;
    @extend .shadow-2;

    .chevron-right {
      transform: translateX(5px);
    }
  }

  &:active {
    @extend .bg-light-gray-2;
  }

  &:focus:not(:active) {
    outline: solid 2px variables.$color-outline;

    .chevron-right {
      transform: translateX(5px);
    }
  }

  .chevron-right {
    transition: transform 0.2s ease-in-out;
  }

  &__copy {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 20px;
  }

  &__name {
    @extend .font-bold;
  }

  &__available-studies {
    @extend .font-normal;
    @extend .text-sm;
    text-decoration: none !important;
  }

  // Variant
  &--studies {
    .chevron-right {
      fill: variables.$color-text;
    }

    &:hover {
      text-decoration: none;

      .link-panel__name {
        text-decoration: underline;
      }
    }
  }

  &--studies-large {
    @extend .link-panel--studies;

    .link-panel__copy {
      flex-direction: column;
    }

    .link-panel__available-studies {
      margin-top: 12px;
    }
  }
}

.link-panel-empty {
  @extend .bg-light-white;
  @extend .text-dark-black;
  @extend .text-md;
  width: 100%;
  margin-top: 0;
  padding: 16px 20px 16px 16px;
  border-top: 2px solid variables.$color-primary;

  &__name {
    @extend .font-bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__content {
    @extend .mt-sm;
    @extend .mb-xs;
    @extend .font-normal;
    @extend .text-sm;
  }
}
