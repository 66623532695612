.text-2xl {
  font-size: 30px;
}

.text-xl {
  font-size: 20px;
}

.text-lg {
  font-size: 18px;
}

.text-md {
  font-size: 16px;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}