@use "sass:map";
@use '../../../sass/base/index.scss';
@use "../../../sass/base/variables";

.subject-grades-section {
  &::before {
    content: 'test';
  }

  .subject-display {
    &__label {
      @extend .text-xs;
      text-transform: uppercase;
    }

    &__input {
      .input-radio__item {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }

      label:hover,
      input:focus+label {
        .input-radio__copy {
          visibility: visible;
        }
      }

      input:focus+label .input-radio__copy {
        .mouse-click & {
          visibility: hidden;
        }
      }

      .input-radio__copy {
        @extend .bg-dark-gray-1;
        @extend .text-light-white;
        padding: 4px;
        position: absolute;
        white-space: nowrap;
        visibility: hidden;
        z-index: 10;
        top: 100%;

        &:before {
          content: '';
          position: absolute;
          left: 20px;
          bottom: 100%;
          border-bottom: 5px solid map.get(variables.$background-dark, gray-1);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }

      // Adjust radio input styling
      [value='maximized']+label:before {
        content: url(../../../assets/maximized.svg);
      }

      [value='minimized']+label:before {
        content: url(../../../assets/minimized.svg);
      }

      :checked+label:before {
        border-color: variables.$color-primary;
      }

      input+label:before {
        margin-right: 0;
        background: variables.$color-white !important;
        border-radius: 0;
        padding: 7px;
        border: 3px solid transparent;
        box-sizing: content-box;
      }
    }
  }
}
