.frontpage-heading {
  display: flex;
  margin-bottom: 16px;

  & h1 {
    margin-left: 16px;
  }
}

.p-container p:first-child {
  font-weight: 600;
}