@use "../base/variables.scss" as var;
@use "../base/mixins.scss" as b;

.x-wrapper {
  max-width: var.$content-width;
  margin-left: auto;
  margin-right: auto;
}

.page-layout {
  display: flex;
  flex-direction: column;

  @include b.breakpoint(max-width lg) {
    padding-bottom: 108px;
  }

  @include b.breakpoint(max-width md) {
    padding-bottom: 91px;
  }

  @include b.breakpoint(lg) {
    flex-direction: row-reverse;
  }

  &__aside {
    position: relative;

    @include b.breakpoint(lg) {
      // background-color: map-get($background-light, gray-1);
      margin-right: 24px;
    }

    @include b.breakpoint(max-width lg) {
      margin-top: 20px;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }

  &__main {
    flex-grow: 1;
  }
}
