@use "../../../../sass/base/index.scss";
@use "../../../../sass/base/variables";

.input-radio.input-radio--points {
  display: flex;
  justify-content: space-between;

  .input-radio__item {
    margin-right: 0;
  }

  input {
    +label:before {
      display: none;
    }

    &:checked+label {
      @extend .font-bold;
      background-color: variables.$color-primary;
    }

    &:focus {
      +label {
        @extend .outline-shadow;
      }
    }

    &:not([disabled])+label {
      &:hover {
        @extend .shadow-1;
      }

      &:active {
        @extend .bg-light-gray-2;
        box-shadow: none;
      }
    }

    &[disabled]+label {
      opacity: .3;
    }
  }

  label {
    background-color: variables.$color-white;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    display: flex;
    justify-content: center;
    ;
    align-items: center;
  }
}
