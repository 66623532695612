@use '../../../sass/base/index.scss';
@use "../../../sass/base/variables";

.subject {
  padding-top: 24px;
  border-top: 1px solid variables.$color-text;

  &:not(:last-of-type) {
    padding-bottom: 24px;
  }

  >* {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__id {
    @extend .text-sm;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__name {
    @extend .text-xl;
    @extend .font-sans;
    @extend .font-bold;
    margin-top: 0;
    text-align: left;
  }

  &__check-subject-renewal {
    margin-bottom: 16px;
  }

  &__check-examn {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__remove {
    @extend .font-bold;
    @extend .text-sm;
    border: none;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;

    svg {
      fill: variables.$color-primary-darken;
      margin-left: 12px;
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      svg {
        transform: scale(1.27);
      }
    }
  }

  &__btn-maximize {
    @extend .text-md;
    padding: 8px 0 12px;
    background: none;
    border: none;
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  &__examn {
    @extend .mt-xs;
    text-transform: uppercase;
  }

  &--collapsed {
    padding: 0 !important;

    .subject {
      &__name {
        margin-bottom: 0;
      }

      &__grade {
        @extend .font-bold;
        @extend .text-xl;
        white-space: nowrap;
        margin-left: 12px;
      }
    }
  }

  .remove {
    &__buttons {
      line-height: 1;
    }

    &__cancel,
    &__confirm {
      color: variables.$color-link;
      background: none;
      border: none;
      padding: 0;
      text-decoration: underline;

      &:hover {
        @extend .text-dark-gray-1;
      }
    }

    &__cancel {
      margin-right: 20px;
    }
  }

  &--remove {
    position: relative;

    &:before {
      content: '';
      @extend .bg-light-gray-2;
      position: absolute;
      top: 0;
      right: -20px;
      bottom: 0;
      left: -20px;
    }

    >* {
      z-index: 5;
      position: relative;
    }
  }
}
