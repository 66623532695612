@use "sass:map";
@use '../../../sass/base/index.scss';
@use "../../../sass/base/variables";

.input {
  position: relative;

  label {
    display: flex;
    align-items: baseline;
  }

  input {
    margin-left: 10px;
    flex-grow: 1;

    &[type='number'] {
      padding: var(--s-4) var(--s-5);
      color: var(--fg);
      font-family: var(--sans);
      font-size: var(--font-size);
      background: var(--fs-on);
      border: none;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: var(--primary);
      outline: none;

      &:hover:not(:focus) {
        background-color: var(--primary-hover-bg);
      }

      &:focus {
        outline: var(--outline);
        outline-offset: 1px;
        border-bottom-color: var(--focus);
      }

      &:invalid {
        background-color: var(--warning-bg) !important;
        border-bottom-color: var(--error) !important;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
      }
    }

    &[type='search'] {
      --_width: 100%;
      -webkit-appearance: textfield;
      appearance: textfield;
      /* Make `search` stylable. */
      background: var(--bg);
      border: none;
      border-radius: 999em;
      box-shadow: inset 0 0 0 1px var(--primary);
      color: var(--fg);
      display: inline-flex;
      font-family: var(--sans);
      font-size: var(--font-size);
      outline: none;
      padding: 0 3em 0 1em;
      height: 2.75em;
      line-height: 2.75;
      width: var(--search-input-width, var(--_width));
      padding-right: 40px;
      appearance: none;

      &:focus {
        outline: var(--outline);
        outline-offset: var(--outline-offset);
      }

      &:not(:focus):hover {
        box-shadow: inset 0 0 0 2px var(--primary);
      }

      &::-webkit-search-cancel-button {
        appearance: none;
      }
    }
  }

  &.is-invalid {
    input:not(:focus) {
      border-bottom-color: variables.$color-error;
      outline: 2px solid variables.$color-error;
      outline-offset: 2px;
    }
  }

  &--label-above {
    label {
      display: block;
      margin-bottom: 15px;
    }

    input {
      margin-left: 0;
      width: 100%;
    }
  }

  &--label-hidden {
    label {
      @extend .visuallyhidden;
    }

    input {
      margin-left: 0;
      width: 100%;
    }
  }

  &--search {
    svg {}
  }

  &--search-alt {
    input {
      border: 2px solid variables.$color-text;

      &:hover,
      &:focus {
        +svg {
          fill: var(--primary);
        }
      }
    }

    .icon-container {
      --primary-l: 87.5%;
      --primary: hsla(var(--primary-h) var(--primary-s) var(--primary-l) / var(--primary-a));
      /* hsl(28.4 88% 51%) / #f07c14 */
      background-color: var(--primary);
      display: inline-flex;
      position: absolute;
      top: 5px;
      right: 5px;
      height: 40px;
      border-radius: 50%;
      width: 40px;
    }

    svg {
      margin: auto;
    }
  }

  // Custom input range
  &--range {
    position: relative;
  }

  input[type='range'] {
    appearance: none;
    border: none;
    padding: 55px 0 6px;
    background-color: transparent;
    height: 65px;

    &::-webkit-slider-runnable-track {
      background-color: map.get(variables.$background-light, orange-1);
      height: 2px;
      position: relative;
      border-radius: 2px;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background-color: transparent;
      height: 62px;
      width: 31px;
      bottom: 0;
      position: absolute;
      border: none;
    }

    &::-moz-range-track {
      background-color: map.get(variables.$background-light, orange-1);
      appearance: none;
      height: 2px;
      border-radius: 2px;
    }

    // &::-moz-range-progress {}

    &::-moz-range-thumb {
      appearance: none;
      background-color: transparent;
      position: absolute;
      border: none;
      border-radius: 0;
    }

    &::-ms-track {
      background-color: map.get(variables.$background-light, orange-1);
      border-radius: 2px;
      width: 100%;
      border: none;
    }

    &::-ms-fill-lower {
      background-color: map.get(variables.$background-light, orange-1);
    }

    &::-ms-fill-upper {
      background-color: map.get(variables.$background-light, orange-1);
    }

    // &::-ms-thumb {}
  }

  .input__thumb {
    display: inline-block;
    position: absolute;
    pointer-events: none;

    & svg {
      fill: variables.$color-primary;
    }
  }

  .input__value {
    @extend .leading-tight;
    font-size: 10px;
    text-align: center;
    display: block;
    position: absolute;
    top: 10px;
    width: 100%;
  }

  .input__buttons {
    display: flex;
    justify-content: space-between;

    button {
      color: variables.$color-gray;
      padding: 5px !important;
      scale: 1.2;

      &:first-child {
        margin-left: -5px;
      }

      &:last-child {
        margin-right: -5px;
      }

      & svg {
        border-radius: 100px;
      }

      & :hover {
        background-color: variables.$color-hover;
      }
    }
  }
}
