@use '../../../sass/base/index.scss';
@use "../../../sass/base/variables";

.category-list {
  &__title {
    @extend .font-normal;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__item {
    margin-top: 10px;

    &:first-of-type {
      margin-top: 0;
      border-top: 2px solid variables.$color-primary;
    }
  }
}

.checkbox {
  @extend .mt-lg;
}
