@use "../../sass/base/variables";
@use '../../sass/base/index.scss';

.accordion {
  @extend .bg-light-white;

  ul {
    @extend .list-reset;
  }

  li {
    @extend .mb-xs;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  &.is-open {
    @extend .shadow-2;
  }

  &__header {
    background: none;
    border: none;
    display: flex;
    align-items: flex-start;
    padding: 15px 10px;
    font-size: inherit;
    text-align: left;
    width: 100%;

    &:hover {
      .icon {
        transform: scale(1.1);
      }
    }

    .icon {
      flex-shrink: 0;
      width: 16px;
      height: 11.5px;
      margin-left: 5px;
      color: variables.$color-link-orange;
      position: relative;
      top: 5px;
      transition: transform 0.2s ease-in-out;

      .is-open.accordion & {
        transform: scaleY(-1);
      }
    }
  }

  &__title {
    @extend .leading-normal;
    @extend .font-bold;
    color: variables.$color-text !important;
    flex-grow: 1;
  }

  &__body {
    padding: 0 10px 15px;

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  +.accordion {
    @extend .mt-sm;
  }
}
