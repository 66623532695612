@use '../../sass/base/index.scss';
@use "../../sass/base/mixins";
@use "../../sass/base/variables";


.nav-tabs {
  display: flex;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .button {
    margin: 8px 0;

    .faq-icon {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.3em;
      margin-right: 0.5rem;

      & path {
        stroke: #fff !important;
        stroke-width: 1.4;
      }
    }

  }

  .button:hover path {
    stroke: #f5decb !important;
  }

  a {
    display: block;
    padding: 16px;
    text-decoration: none;
    color: inherit;
  }

  ul {
    @extend .list-reset;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-left: -15px;
  }

  &__tab {
    display: inline-block; // IE fallback

    &::before {
      content: unset;
    }

    &:not(:last-child) {
      background-image: linear-gradient(to bottom, variables.$color-gray, variables.$color-gray);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: 1px 27px;
    }
  }

  .is-active {
    @extend .font-bold;
    background-image: linear-gradient(to right, variables.$color-primary, variables.$color-primary);
    background-position: bottom 10px left 15px;
    background-repeat: no-repeat;
    background-size: calc(100% - 30px) 3px;
  }
}
@include mixins.breakpoint(max-width md) {
  .branding{
    max-width: 50vw;
  }
  .nav-tabs {
    .faq-button {
      height: fit-content;
      .faq-label {
        // .visuallyhidden from sass/utils/misc.scss, cant @extend in a media query
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
      .faq-icon {
        margin-right: 0;
      }
      padding: 0;
    }
  }
}
