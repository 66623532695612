@use "sass:map";
// Colors
$primary-color: (
  base: #f89330,
  darken: #f07c14,
  lighten: #ff9c43,
);

$extra-color: (
  a11y-orange: #f06e00,
  a11y-blue: #007dbf,
  red: #ff2f57,
  green: #0ca87e,
);

$text-dark: (
  black: #000000,
  gray-1: #585858,
  gray-2: #767676,
);

$text-light: (
  white: #ffffff,
);

// Font-weight
$fw-normal: 400;
$fw-bold: 600;

$background-dark: (
  gray-1: #333,
);

$background-light: (
  white: var(--bg),
  gray-1: var(--bg-base),
  gray-2: var(--bg-muted),
  orange-1: #f3c59e,
  orange-2: map.get($primary-color, base),
  orange-3: map.get($primary-color, darken),
);

$color-primary: var(--primary);
$color-primary-darken: map.get($primary-color, darken);
$color-hover: var(--primary-hover-bg);
$color-text: var(--DARK-GRAY);
$color-text-white: map.get($text-light, white);
$color-placeholder: map.get($text-dark, gray-2);
$color-placeholder-dark: map.get($text-dark, gray-1);
$color-outline: map.get($extra-color, a11y-blue);
$color-link: map.get($extra-color, a11y-blue);
$color-link-orange: map.get($extra-color, a11y-orange);
$color-error: map.get($extra-color, red);
$color-success: map.get($extra-color, green);
$color-shadow: rgba($color-text, 0.16);
$color-white: map.get($background-light, white);

$color-orange: #f06e00;
$color-orange-light: #ff9c43;

$color-blue: #007dc0;
$color-red: #ff2f56;
$color-green: #0ba87e;

$color-gray: #767676;
$color-gray-dark: #585858;

// Dimensions

$content-width: 1200px;

// Layout
$layout-margin: 24px;

// Breakpoints
$global-breakpoints: (
  sm: 400px,
  md: 768px,
  lg: 1000px,
);

// Spacing
$spacing: (
  2xs: 4px,
  xs: 8px,
  sm: 12px,
  md: 16px,
  lg: 24px,
  xl: 40px,
);