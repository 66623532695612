@use "./variables.scss" as var;
@use "./mixins.scss" as b;
@use "../utils/misc.scss";

.calculator-header {
  @extend .shadow-2;
  position: relative;
  z-index: 10;
  padding-left: var.$layout-margin;
  padding-right: var.$layout-margin;

  @include b.breakpoint(lg) {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}
