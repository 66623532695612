@use "sass:map";
@use '../../sass/base/index.scss';
@use "../../sass/base/mixins";
@use "../../sass/base/variables";

.tooltip-label {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 10px;
  padding: 0;

  & h2 {
    margin: 0;
  }

  .icon-question {
    position: relative;
  }

  legend {
    margin-bottom: 0;
  }

  &__toggle {
    margin-left: 8px;
    border: none;
    background: none;

    svg {
      border-radius: 99px;

      path {
        stroke: variables.$color-text;
      }
    }

    &:hover svg {
      @include mixins.breakpoint(min-width lg) {
        fill: variables.$color-hover;
      }
    }
  }

  &--no-label {
    @extend .tooltip-label;
  }
}

.tooltip-wrapper {
  position: relative;

  @include mixins.breakpoint(md) {
    display: inline-block;
  }
}

.tooltip {
  @extend .bg-dark-gray-1;
  @extend .text-sm;
  @extend .leading-normal;
  padding: 30px variables.$layout-margin;
  color: variables.$color-text-white;
  position: relative;

  @include mixins.breakpoint(lg) {
    position: absolute;
    z-index: 10;
    top: 100%;
    max-width: 400px;
    min-width: 250px;
  }

  @include mixins.breakpoint(max-width md) {
    margin-left: -(variables.$layout-margin);
    margin-right: -(variables.$layout-margin);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    height: 0;
    width: 0;
    border-bottom: 11px solid map.get(variables.$background-dark, gray-1);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
  }

  &__header {
    @extend .font-bold;
    @extend .mb-xs;
    display: flex;

    .icon-question {
      margin-right: 10px;

      & path {
        stroke: #fff !important;
      }
    }
  }

  &__close {
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    margin-left: auto;

    & path {
      fill: #fff;
    }
  }
}
