@use "../../sass/base/index.scss";
@use "../../sass/base/variables";

.scoreboard-mini {
  font-size: 16px;
  color: variables.$color-text;

  .page-layout__aside & {
    border-left: 2px solid variables.$color-primary;
    background-color: variables.$color-white;
    padding: 20px 25px;
  }

  &__body {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;

    .page-layout__main & {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__summary {
    @extend .leading-tight;
    margin-bottom: 0;

    .page-layout__aside & {
      font-size: 20px;
      font-weight: variables.$fw-bold;
    }

    span {
      @extend .font-bold;
      @extend .text-2xl;
      display: block;

      .page-layout__aside & {
        font-size: 50px;
      }
    }
  }
}
