@use "../base/variables.scss" as var;

// Color utility
@each $color,
$hex in var.$text-dark {
  $color-name: ""+$color;

  .text-dark-#{$color-name} {
    color: $hex;
  }
}

@each $color,
$hex in var.$text-light {
  $color-name: ""+$color;

  .text-light-#{$color-name} {
    color: $hex;
  }
}

@each $color,
$hex in var.$background-dark {
  $color-name: ""+$color;

  .bg-dark-#{$color-name} {
    background-color: $hex;
  }
}

@each $color,
$hex in var.$background-light {
  $color-name: ""+$color;

  .bg-light-#{$color-name} {
    background-color: $hex;
  }
}
