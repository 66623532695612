@use "sass:map";
@use '../../../sass/base/index.scss';
@use "../../../sass/base/mixins";
@use "../../../sass/base/variables";

.intro-form {
  .tooltip-wrapper {
    @extend .mb-sm;
  }

  .checkbox-header {
    margin: 0;
  }

  p {
    max-width: map.get(variables.$global-breakpoints, md);
  }

  .input-select {
    max-width: map.get(variables.$global-breakpoints, sm);
  }

  .button--cta {
    margin-top: 25px;

    @include mixins.breakpoint(max-width md) {
      margin-left: auto;
    }
  }
}

.bottom-section {
  @include mixins.breakpoint(max-width lg) {
    padding-bottom: 20px;
  }
}

.checkbox-container label:first-child {
  margin-top: 12px !important;
}
