@use "sass:map";
@use "../base/variables.scss" as var;
@use "../base/mixins.scss" as b;

.grid-split {
  display: grid;
  grid-gap: map.get(var.$spacing, xl) map.get(var.$spacing, md);
  grid-template-columns: minmax(100px, auto);

  @include b.breakpoint(md) {
    grid-template-columns: 1fr 1fr;
  }
}
