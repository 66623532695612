@use "../../sass/base/index.scss";
@use "../../sass/base/variables";

.expandable-field {
  &__toggle {
    @extend .text-md;
    @extend .font-bold;
    text-align: left;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;

    &:hover {
      .icon-plus {
        @extend .bg-light-orange-2;
        @extend .shadow-2;
      }
    }

    &:active {
      .icon-plus {
        @extend .bg-light-orange-3;
      }
    }

    .icon-plus {
      border: 1.5px solid variables.$color-primary;
      padding: 3px;
      box-sizing: content-box;
      border-radius: 20px;
      margin-left: 10px;
      transition: transform 0.2s ease-in-out;

      .is-open>& {
        transform: rotate(90deg);

        .horizontal {
          display: none;
        }
      }
    }

    .icon-chevron {
      margin-left: 10px;
      width: 12px;
      height: 9px;
      position: relative;
      top: -2px;
      color: variables.$color-primary;
      transition: transform .2s ease-in-out;

      .is-open>& {
        transform: scaleY(-1);
      }
    }
  }

  &__content {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    height: 0;

    > :last-child {
      margin-bottom: 0;
    }

    .is-open>& {
      @extend .mt-md;
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }


}

.filter {
  border-color: #333;
}
