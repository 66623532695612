.accordion .accordion__body ul {
  list-style: disc;
  padding-left: 1.25rem;
  margin: 0 0 0.75em;
}

.accordion .accordion__title {
  color: var(--DARK-GRAY) !important;
}

.modal .modal__header button svg path {
  fill: var(--DARK-GRAY) !important;
}