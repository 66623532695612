@use '../../../sass/base/index.scss';
@use "../../../sass/base/mixins";

.subject-search {
  &__results {
    @extend .bg-light-gray-1;
    @extend .shadow-2;

    @include mixins.breakpoint(md) {
      position: absolute;
      z-index: 5;
      padding: 5px;
      max-height: 50vh;
      max-width: 358px;
      width: 100%;
      overflow-y: auto;
    }
  }
}

.input--label-above .icon-container {
  top: 38px !important;
}
