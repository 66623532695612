@use "sass:map";
@use '../../sass/base/index.scss';
@use "../../sass/base/variables";

.modal-wrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(map.get(variables.$background-dark, gray-1), 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  .modal {
    transform: translateY(-100px);
    transition: transform 0.2s ease-in-out;
  }

  &.is-open {
    visibility: visible;
    opacity: 1;

    .modal {
      transform: translateY(0);
    }
  }
}

.modal {
  @extend .bg-light-gray-1;
  min-height: 200px;
  min-width: 200px;
  max-width: 700px;
  max-height: 98%;
  margin: variables.$layout-margin;
  padding: 15px 25px;
  border-radius: 7px;
  overflow-y: auto;

  &__header {
    @extend .text-xl;
    @extend .font-bold;
    @extend .leading-tight;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding: 10px 0 5px;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 60px;
      border-bottom: 2px solid variables.$color-primary;
    }

    button {
      margin-left: 15px;
      margin-right: -10px;
      border: none;
      padding: 0;
      background: none;
      position: relative;
      top: 3px;

      &:svg path {
        stroke: variables.$color-text;
      }

      &:hover {
        color: variables.$color-error;
      }
    }

    .icon {
      width: 17px;
      height: 17px;
    }
  }

  &__body {
    @extend .mt-md;
  }
}
