@use '../../../../sass/base/index.scss';

.requirement-group {
  &__label {
    @extend .mb-xs;
  }

  li {
    @extend .mb-xs;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  li ul {
    @extend .mt-xs;
    padding-left: 25px;
  }
}
