@use "../base/variables.scss" as var;
@use "../base/mixins.scss" as b;

.shadow-1 {
  box-shadow: 0 2px 3px var.$color-shadow;
}

.shadow-2 {
  box-shadow: 0 1px 2px rgba(var.$color-text, 0.1);

  @media (min-width: 800px) {
    box-shadow: 0 1px 3px rgba(var.$color-text, 0.16);
  }
}

.outline-shadow {
  outline: none;
  box-shadow: 0 0 0 2px var.$color-text-white, 0 0 0 4px var.$color-outline;

  body.mouse-click & {
    box-shadow: none;
  }

  &--error {
    box-shadow: 0 0 0 2px var.$color-text-white, 0 0 0 4px var.$color-error;
  }
}

.shadow-none {
  box-shadow: none;
}

// Helper classes
.underline {
  text-decoration: underline;
}

.underline-double {
  padding-bottom: 8px;
  @include b.double-lines(var.$color-text);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 6px;
}

.highlight,
.underline-highlight {
  padding-bottom: 0.2em;
  background-image: linear-gradient(to right, var.$color-primary, var.$color-primary);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 3px;
}

.-layout-margin {
  margin-right: -(var.$layout-margin);
  margin-left: -(var.$layout-margin);
}

.list-reset {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

// Hide visually and from screen readers
.hidden,
[hidden] {
  display: none !important;
}

// Hide only visually, but have it available for screen readers:
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
// 1. For long content, line feeds are not interpreted as spaces and small width
//    causes content to wrap 1 word per line:
//    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
.visuallyhidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

// Extends the .sr-only class to allow the element
// to be focusable when navigated to via the keyboard:
// https://www.drupal.org/node/897638
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

// Hide visually and from screen readers, but maintain layout
.invisible {
  visibility: hidden;
}

// Show/hide based on window width
.md-up {
  @include b.breakpoint(max-width md) {
    display: none;
  }
}

.lg-up {
  @include b.breakpoint(max-width lg) {
    display: none;
  }
}

.md-down {
  @include b.breakpoint(md) {
    display: none;
  }
}

.lg-down {
  @include b.breakpoint(lg) {
    display: none;
  }
}


.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

#user-menu {
  z-index: 11 !important;
}

.disclaimer {
  max-width: 1200px;
  padding: 1em;
  margin: 1em auto;
  background: #fff;
  color: #000;
  border: orange solid 1px;
  border-radius: 8px;
}
