@use "./variables.scss" as var;
@use "../utils/index.scss";

html {
  @extend .text-md;
  @extend .leading-normal;
  @extend .font-normal;
  @extend .font-sans;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

p {
  margin: 0 0 0.75em;

  &.large {
    @extend .text-lg;
  }

  &.small {
    @extend .text-sm;
  }
}

small {
  @extend .text-sm;
}

:focus {
  outline: 2px solid var.$color-outline;
  outline-offset: 2px;

  body.mouse-click & {
    outline: none;
  }
}

label {
  @extend .leading-tight;
}

button {
  appearance: none;
  padding: 0;
  color: var(--DARK-GRAY);

  &:svg path {
    stroke: var(--DARK-GRAY);
  }

  &:not([disabled]) {
    cursor: pointer;
  }
}
