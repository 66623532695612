@use '../../../sass/base/index.scss';

.study-list {
  &__title {
    @extend .font-normal;
    @extend .mb-xs;

    .primary {
      text-transform: uppercase;
    }

    .sub {
      @extend .text-md;
      @extend .font-bold;
      @extend .mt-xs;
      display: block;
    }
  }


  &__item {
    margin-top: 50px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
