@use "./variables.scss" as var;
@use "sass:list";
@use "sass:map";
@use "sass:meta";
@mixin mediaQuery($params) {
  $prefix: min-width;
  $width: map.get($params, width);

  @if map.has-key($params, prefix) {
    $prefix: map.get($params, prefix);
  }

  @if map.has-key(var.$global-breakpoints, $width) {
    @media (#{$prefix}: map.get(var.$global-breakpoints, $width)) {
      @content;
    }
  }

  @else if meta.type-of($width)==number {
    @media (#{$prefix}: $width) {
      @content;
    }
  }

  @else {
    @warn '\'#{$width}\' does not exist as key in  $global-breakpoints map';
  }
}

@mixin breakpoint($params) {
  @if list.length($params)==1 {
    $width: $params;

    @include mediaQuery((width: $width,
      )) {
      @content;
    }
  }

  @else if list.length($params)==2 {
    $prefix: list.nth($params, 1);
    $width: list.nth($params, 2);

    @include mediaQuery((width: $width,
        prefix: $prefix,
      )) {
      @content;
    }
  }
}

// Used for creating double underlines
@mixin double-lines($color1, $color2: transparent) {
  background-image: linear-gradient(to bottom,
      $color1 25%,
      $color2 25%,
      $color2 50%,
      $color1 50%,
      $color1 75%,
      $color2 75%);
}
