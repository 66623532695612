@use '../../sass/base/index.scss';
@use "../../sass/base/mixins";
@use "../../sass/base/variables";

.scoreboard {
  @extend .text-sm;
  @extend .text-light-white;

  @include mixins.breakpoint(lg) {
    font-size: 16px;
    color: variables.$color-text;
    border-left: 2px solid variables.$color-primary;
  }

  &__body {
    @extend .bg-dark-gray-1;
    max-height: 95vh;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.breakpoint(lg) {
      grid-template-columns: 1fr;
      background-color: variables.$color-white;
    }

    .button {
      width: 100%;
      justify-self: center;
      background: variables.$color-white;
      grid-column: span 2;
      max-width: 400px;

      &:hover {
        background-color: variables.$color-hover;
      }

      &:focus {
        background: #007dbf;
        color: variables.$color-text-white;
      }
    }

    .is-closed & {
      @include mixins.breakpoint(max-width lg) {
        grid-row-gap: 0;

        .scoreboard__details {
          display: none;
        }
      }

      .button {
        display: none;
      }
    }

    .is-above-22 & {
      @include mixins.breakpoint(max-width lg) {
        text-align: center;
      }
    }

    @include mixins.breakpoint(max-width lg) {
      > :nth-child(n + 3) {
        text-align: right;
      }
    }

    @include mixins.breakpoint(max-width md) {
      > :nth-child(4) {
        text-align: left;
      }
    }
  }

  &__toggle {
    cursor: pointer;
    @extend .bg-dark-gray-1;
    @extend .text-light-white;
    width: 113px;
    height: 22px;
    border-radius: 20px 20px 0 0;
    clip-path: url(#curved-button);
    border: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1px;
    transition: clip-path 1s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:focus {
      .chevron-down {
        path {
          fill: variables.$color-primary;
        }
      }
    }

    .chevron-down {
      position: relative;
      left: 2px;
      top: 2px;
      transition: transform 0.2s ease-in-out;

      path {
        fill: variables.$color-white;
      }

      .is-closed & {
        transform: scaleY(-1);
      }
    }

    &:focus {
      outline: none;
    }

    @include mixins.breakpoint(lg) {
      display: none;
    }
  }

  &__summary {
    @extend .leading-tight;
    margin-bottom: 0;

    &:nth-of-type(3) {
      grid-area: 1 / 2;

      @include mixins.breakpoint(lg) {
        grid-area: unset;
      }
    }

    @include mixins.breakpoint(max-width lg) {
      .is-above-22 & {
        grid-column: 1/-1;
      }
    }

    @include mixins.breakpoint(md) {
      font-size: 16px;
    }

    @include mixins.breakpoint(lg) {
      font-size: 20px;
      font-weight: variables.$fw-bold;
    }

    span {
      @extend .font-bold;
      @extend .text-2xl;
      display: block;

      @include mixins.breakpoint(md) {
        font-size: 40px;
      }

      @include mixins.breakpoint(lg) {
        font-size: 50px;
      }
    }
  }

  &__details {
    grid-column: 1 / -1;
    transition: opacity 0.2s ease-in-out;

    strong {
      display: inline-block;
    }

    @include mixins.breakpoint(md) {
      grid-column: inherit;
    }

    .is-above-22 & {
      @include mixins.breakpoint(max-width lg) {
        grid-column: 1/-1;
      }
    }

    .is-closed & {
      @include mixins.breakpoint(max-width lg) {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
      }
    }
  }

  .underline-double {
    @include mixins.breakpoint(max-width lg) {
      @include mixins.double-lines(variables.$color-text-white);
    }
  }

  .tooltip {
    @include mixins.breakpoint(max-width lg) {
      border: 1px solid variables.$color-white;

      &::before {
        border-bottom-color: variables.$color-white;
      }
    }
  }

  .tooltip-wrapper {
    display: inline-block;

    .tooltip-label__toggle {
      @include mixins.breakpoint(max-width lg) {
        svg {
          &:hover {
            fill: #333 !important;
          }

          path {
            stroke: variables.$color-text-white !important;
          }
        }
      }
    }
  }
}

.points-container {
  display: flex;
  flex-direction: column;
  gap: 25px;

  @include mixins.breakpoint(max-width lg) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
}
