@use '../../sass/base/index.scss';
@use "../../sass/base/mixins";

.section {
  @extend .bg-light-gray-2;
  margin-top: 20px;
  position: relative;

  p {
    word-break: keep-all;
  }

  >* {
    margin-top: 24px;
  }

  @include mixins.breakpoint(lg) {
    margin-top: 24px;
  }

  .page-layout__aside>&:first-child,
  .page-layout__main>&:first-child {
    margin-top: 0;

    .section__row {
      margin-top: 0;
    }
  }

  .page-layout__aside &:first-child {
    .section__row {
      @include mixins.breakpoint(max-width lg) {
        padding-top: 0;
      }
    }
  }

  &:before {
    @extend .bg-light-gray-2;
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    .page-layout__aside & {
      left: calc(-50vw + 600px);

      @include mixins.breakpoint(max-width lg) {
        background: none;
      }
    }

    .page-layout__main & {
      right: calc(-50vw + 600px);
    }
  }

  .page-layout__aside & {
    @include mixins.breakpoint(max-width lg) {
      background: none;
    }

    // Sticky sidebar
    @include mixins.breakpoint(lg) {
      height: 100%;

      .section__row {
        position: sticky;
        top: 0;
      }
    }
  }

  &__row {
    box-sizing: content-box;
    max-width: 455px;

    @include mixins.breakpoint(lg) {
      padding: 49px;
    }

    .page-layout__aside & {
      max-width: none;

      @include mixins.breakpoint(lg) {
        max-width: 320px;
      }
    }

    .page-layout__main & {
      padding: 24px 20px;

      @include mixins.breakpoint(md) {
        padding-left: 104px;
      }
    }
  }

  &__row--wide {
    @extend .section__row;
    max-width: 552px;
  }

  &__row--narrow {
    @extend .section__row;
    max-width: 358px;
  }
}
