@use "../utils/font-size.scss";

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

input,
select {
  border-radius: 0;
}

input {
  @extend .text-md;
}
