@use "../../../sass/base/index.scss";
@use "../../../sass/base/variables";

.input-radio {
  &__item {
    position: relative;
  }

  input[type="radio"] {
    @extend .visuallyhidden;

    +label {
      display: flex;
      align-items: center;

      &:before {
        content: "";
        margin-right: 7px;
        flex-shrink: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        border: 2px solid variables.$color-text;
      }
    }

    &:checked {
      +label:before {
        background-image: radial-gradient(variables.$color-text 6px, transparent 6px);
      }
    }

    &:hover {
      +label:before {
        @extend .bg-light-orange-1;
      }
    }

    &:focus {
      +label:before {
        z-index: 10;
        @extend .outline-shadow;
      }
    }
  }

  &--horizontal {
    .input-radio__item {
      display: inline-block;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--vertical {
    .input-radio__item+.input-radio__item {
      margin-top: 15px;
    }
  }
}
