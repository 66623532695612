@use '../../../sass/base/index.scss';

.breadcrumb {
  a {
    color: inherit;
  }

  ul {
    @extend .list-reset;
    display: flex;
    flex-wrap: wrap;
  }

  li:not(:last-child) {
    &:after {
      content: url(../../../assets/chevron-right.svg);
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
